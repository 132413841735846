import React, { useEffect } from "react"
import { useLazyQuery } from '@apollo/client'
import { GET_SEARCH_RESULTS } from '../graphql/queries'
import SearchItem from 'components/searchItem'

export default ({ searchValue }) => {

    const [getSearchResults, { data: searchResults }] = useLazyQuery(GET_SEARCH_RESULTS)

    let _searchResults = []

    useEffect(() => {
        getSearchResults({
            variables: {
                term: searchValue
            },
        })
    }, [getSearchResults, searchValue])

    _searchResults = searchResults && searchResults.contentNodes.nodes

    if (_searchResults) {
        _searchResults = _searchResults.filter(item => {

            if (
                item.id !== 'cG9zdDoyMDE1' &&
                item.id !== 'cG9zdDoxMTIx' &&
                item.id !== 'cG9zdDo4MTk=' &&
                item.id !== 'cG9zdDo0NDY=' &&
                item.__typename !== 'ChristmasPrize'
            ) {
                return item
            } else {
                return null
            }
        })
    }

    return (
        <div className="search">
            <div className="container">
                <h1 dangerouslySetInnerHTML={{ __html: 'Search results' }} />
                <form method="get" action="/search">
                    <input name="s" type="text" placeholder="What are you looking for?" defaultValue={searchValue} />
                    <button type="submit">
                        <i className="fe fe-search"></i>
                    </button>
                </form>
                <div
                    className="search-number"
                    dangerouslySetInnerHTML={{ __html: _searchResults ? _searchResults.length === 1 ? _searchResults.length + ' Result showing' : _searchResults.length + ' Results showing' : '' }}
                />
                {_searchResults ?
                    _searchResults.map((row, index) => {
                        return (
                            <SearchItem item={row} key={index} />
                        )
                    })
                    :
                    <p dangerouslySetInnerHTML={{ __html: 'Loading...' }} />
                }
            </div>
        </div>
    )
}
