import React from "react"
import Link from 'components/link'

export default ({ item }) => {
    const {
        title,
        content,
        featuredImage,
    } = item

    let excerpt = content ? content.replace(/(<([^>]+)>)/ig,"") : ''
    excerpt = excerpt ? excerpt.slice(0,160) : ''
    if (excerpt.length === 160) {
        excerpt = excerpt + '...'
    }

    return (
        <div className="search-item">
            <Link to={item.uri}>
                <div className="search-item-image-cont">        
                    <div className={ featuredImage ? 'search-item-image' : 'search-item-image noImage' } style={{ backgroundImage: `url(${ featuredImage ? featuredImage.node.mediaItemUrl : '' })` }} />
                </div>
                <div className="search-item-text">
                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                    <button className="button" dangerouslySetInnerHTML={{ __html: 'Read more' }} />
                </div>
            </Link>
        </div>
    )
}
