import React from "react"
import { graphql } from "gatsby"
import { getUrlParam } from "utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Search from "../components/search"

export default ({ data, location }) => {
  const {
    title,
    excerpt,
  } = data.siteFields.page

  const searchValue = getUrlParam('s')

  return (
    <Layout location={ location }>
      <SEO title={title} description={excerpt} />
      <Search searchValue={ searchValue } />
    </Layout>
  )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                date
                content(format: RENDERED)
                featuredImage {
                    node {
                      altText
                      title(format: RENDERED)
                      mediaItemUrl
                      slug
                    }
                }
            }
        }
    }
`
